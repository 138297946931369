import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import Hero from "Layout/Hero/Hero"
import MainLayout from "Layout/layout";
import BookingSection from "Layout/BookingSection/BookingSection";
import SliderAttraction from "Layout/Slider/SliderAttraction/SliderAttraction";
import TabAccordion from 'Layout/TabAccordion/TabAccordion';
import Footer from "Layout/Footer/Footer";
import config from "data/SiteConfig";


const Birthday = (props) => {
  const { location, pageContext, data } = props;
  const { page, optionsPage } = data;

  return (
    <MainLayout location={location} pageContext={pageContext}>
      <Helmet
        meta={[
          {
            property: 'og:title',
            content: page.acf.meta_info_title,
          },
          {
            property: 'og:description',
            content: page.acf.meta_info_description,
          },
          {
            property: 'og:image',
            content: page.acf.social_share_image.image.localFile.childImageSharp.fluid.src,
          },
          {
            property: 'og:url',
            content: location.href,
          },
          {
            property: 'og:site_name',
            content: config.siteTitle,
          },
          {
            name: 'twitter:image:alt',
            content: page.acf.meta_info_description,
          }
        ]}
      />
      <Hero
        headline={page.acf.hero.headline}
        text={page.acf.hero.subheadline}
        openingTimes={optionsPage.edges[0].node.options.opening_times}
        waitingTime={optionsPage.edges[0].node.options.news.current_waiting_time}
        hasIcons
      />
      <BookingSection
        headline={page.acf.booking.headline}
        bookingTypes={page.acf.booking.booking_types}
      />
      {
        page.acf.slider.sliders.length > 0 && page.acf.slider.sliders.map(slider => (
          <SliderAttraction
            headline={slider.headline}
            subHeadline={slider.subheadline}
            link={slider.slider_link ? slider.slider_link.source_url : null}
            linkText={slider.slider_link_text}
          >
            {
              slider.slides.length > 0 && slider.slides.map((slide, index) => (
                <div className={`d-flex ml-md-0 justify-content-center ${ index === 0 ? 'ml-1' : 'ml-2'}`}>
                  <div className="d-block rounded-edges overflow-hidden">
                    <img className="d-block mb-1" src={slide.image.localFile.childImageSharp.fluid.src} alt="Attraktion" />
                  </div>
                </div>
              ))
            }
          </SliderAttraction>
        ))
      }
      <TabAccordion
        headline={page.acf.tab.tabs_headline}
        tabs={page.acf.tab.tabs}
      />
      <Footer />
    </MainLayout>
  );
}

export const query = graphql`
  query BirthdayPageQuery($id: String!) {
    page: wordpressPage(id: { eq: $id }) {
      title
      slug
      acf {
        hero {
          headline
          subheadline
        }
        tab {
          tabs_headline
          tabs {
            title
            is_accordion
            accordion_items {
              question
              answer
            }
            list_items {
              item_headline
              item_description
            }
          }
        }
        booking {
          headline
          booking_types {
            title
            info_items {
              info_item
            }
            info_bonus
            info_price
          }
        }
        slider {
          sliders {
            headline
            subheadline
            slider_link {
              source_url
            }
            slider_link_text
            slides {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1366) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
          }
        }
        social_share_image {
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
    optionsPage: allWordpressAcfOptions {
      edges {
        node {
          options {
            opening_times {
              opening_times_headline
              opening_times_additional_notice
              opening_times {
                opening_time_item
              }
            }
            news {
              current_waiting_time
            }
          }
        }
      }
    }
  }
`;

export default Birthday
